import styled from "styled-components";

export const StyledCustomHtml = styled.div`
  overflow: hidden;

  .custom-html-container {
    &__button {
      position: absolute;
      right: 0px;
      padding: 0.25rem;
      --tw-bg-opacity: 1;
      background-color: rgb(156 163 175 / var(--tw-bg-opacity));
      z-index: var(--zindex-custom-html-button);
    }

    &__textarea {
      --tw-bg-opacity: 1;
      background-color: rgb(203 213 225 / var(--tw-bg-opacity));
      width: 100%;
      height: 24rem;
    }
  }
`;
