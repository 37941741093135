import { useMemo } from "react";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { PlateRenderLeafProps } from "@udecode/plate";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import ConditionalWrapper from "../components/ConditionalWrapper/ConditionalWrapper";
import { StyledLinkWrapper } from "../components/LinkWrapper/LinkWrapper";

export const CustomLeaf = ({ attributes, children }: PlateRenderLeafProps) => {
  const theme = useTheme() as IThemeState;

  const memorizedTypo = useMemo(() => {
    return children?.props?.leaf?.["typography"];
  }, [children?.props?.leaf?.["typography"]]);

  const memorizedColor = useMemo(() => {
    return children?.props?.leaf?.["color"];
  }, [children?.props?.leaf?.["color"]]);

  const memorizedBackgroundColor = useMemo(() => {
    return children?.props?.leaf?.["backgroundColor"];
  }, [children?.props?.leaf?.["backgroundColor"]]);

  return (
    <ConditionalWrapper
      condition={
        (memorizedTypo && memorizedTypo !== "none") ||
        memorizedBackgroundColor ||
        (memorizedColor && memorizedColor !== "color$$1")
      }
      wrapper={(children) => {
        return (
          <StyledText
            style={{
              backgroundColor: convertColorIdToHex(
                memorizedBackgroundColor,
                theme?.colorPalette
              ),
            }}
            renderAs="span"
            $settings={{
              font: {
                id: memorizedTypo,
                custom: {},
              },
              text: { color: memorizedColor },
              show: true,
            }}
            {...attributes}
          >
            {children}
          </StyledText>
        );
      }}
    >
      <ConditionalWrapper
        condition={
          children?.props?.leaf?.["linkStyle"] &&
          children.props.leaf["linkStyle"] !== "none"
        }
        wrapper={(children) => (
          <StyledLinkWrapper
            role="link"
            $linkStyle={children.props.leaf["linkStyle"]}
            rel="noreferrer"
            target="_blank"
          >
            {children}
          </StyledLinkWrapper>
        )}
      >
        {children}
      </ConditionalWrapper>
    </ConditionalWrapper>
  );
};
